import './App.css';
import faCalendar from './icons/calendar-regular.svg';
import faCrown from './icons/crown-solid.svg';
import faUser from './icons/user-solid.svg';
import faQuestion from './icons/question-solid.svg';
import faLanguage from './icons/language-solid.svg';
import faBook from './icons/book-solid.svg';
import faPaintbrush from './icons/paintbrush-solid.svg';
import faSearch from './icons/magnifying-glass-solid.svg';
import { useState } from 'react';

const events = `Imre herceg halála / 1031
I. István halála / 1038 aug. 15.
ménfői csata / 1044
NRCS hűbérese Ho. / 1045
1. pogánylázadás / 1046
dukátus létrehozása / 1048
Vértes hegység - NRCS veresége / 1051
NRCS hajó elsüllyesztése Pozsonyban / 1052
tihanyi bencés apátság / 1055
tiszavárkonyi jelenet / 1059
2. pogánylázadás / 1061
cserhalmi csata / 1068
Nándorfehérvár elfoglalása / 1071
mogyoródi csata / 1074
I. István szentté avatása / 1083 aug. 20.
Salamon halála / 1087
Horvátország & Szlovákia elfoglalása / 1091
szabolcsi zsinat / 1092
Przemysl Kálmán / 1099
Kálmán horvát király / 1102
Kálmán Dalmácia meghal / 1105
II. Béla Sajó folyó / 1131
előkelők kivégeztetése / 1132
2. keresztes hadjárat átvonulása / 1147
I. Mánuel & III. István szerződése / 1163
okleveles rögzítés / 1181
kancellária önállósulás / 1185 k.
I. László szentté avatása / 1192 jún. 27.
Modrus megye eladása / 1193
Német lovagrend behívása / 1211
II. András 1. felesége halála / 1213
hadjárat Szentföldre / 1216
Aranybulla / 1222
Privilegium Andreanum / 1224
Német lovagrend kiűzése / 1225
Aranybulla 1. megújítás / 1231
kehidai oklevél / 1232
beregi egyezmény / 1233
muhi csata / 1241
tatárok kivonulása / 1242
1. morvamezei csata / 1260
Aranybulla 2. megújítás / 1267
2. morvamezei csata / 1278
IV. Kun László vs. kunok / 1282
2. tatárjárás / 1285
költözési szabadság / 1298
Caroberto király / 1301
III. Vencel halála / 1306
Károly Róbert 2. koronázás / 1309
Ottó halála / 1312
Rozgony I. Károly csata / 1312
debreceni csata / 1317
ezüstdínár / 1323
aranyforint / 1325
Szent György lovagrend / 1326
bányareform / 1327
Posada I. Károly csata / 1330
I. Károly nápolyi király egyezmény / 1333
visegrádi királytalálkozó / 1335
kapuadó / 1336
trónörökösödési szerződés / 1339
Szendrő vára elvesztése / 1339
nápolyi hadjáratok / 1347 - 1348 ; 1350 - 1352
Aversa I. Lajos sebesül / 1350
ősiség kilenced fiscalitas / 1351
zárai béke / 1358
pécsi egyetem / 1367
1. magyar - lengyel unió / 1370 - 1382
kássai privilégium / 1374
turini béke / 1381
rigómezei csata / 1389
óbudai egyetem / 1395
Nikápoly Luxemburgi Zsigmond csata / 1396
telekkatonaság + jobbágyi törvények / 1397, 1435
Luxemburgi Zsigmond fogság Siklós / 1401
Ankara szultán Timur Lenk / 1402
nápolyi király koronázása Zára / 1403
placetum regium / 1404
városi törvények / 1405
Sárkányrend / 1408
magyar - német unió / 1410 - 1439
konstanzi zsinat / 1414 - 1418
főkegyúri jog / 1417
Luxemburgi Zsigmond vs husziták / 1420 - 1436
Galambóc Luxemburgi Zsigmond csata / 1428
kápolnai unió / 1437
kolozsmonostori egyezmény / 1437
2. magyar - lengyel unió / 1440 - 1444
I. Ulászló Bátaszék csata / 1441
Hunyadi János erdélyi csaták / 1442
győri béke / 1442
Hunyadi János balkán csata / 1443
hosszú hadjárat / 1443 - 1444
drinápolyi béke / 1444
Várna magyar csata / 1444
7 főkapitány választása Pest / 1445
2. rigómezei csata / 1448
II. Mehmed Konstantinápoly / 1453
imabulla déli harang / 1456
nándorfehérvári diadal / 1456
Hunyadi László kivégzése / 1457
Galambóc elvesztése / 1458
III. Frigyes magyar király Németújvár / 1459
Szendrő elfoglalása II. Mehmed / 1459
Jajca elfoglalása Mátyás / 1463
bírósági reformok Mátyás / 1464
Szrebenik elfoglalása / 1464
kincstári reform / 1467
erdélyi lázadás / 1467
I. Mátyás cseh király / 1469
összeesküvés I. Mátyás ellen / 1471
1. könyvnyomda / 1472
Boroszló Mátyás bekerítése / 1474
Szabács elfoglalása Mátyás / 1476
III. Frigyes Jagelló Ulászló választófejedelem / 1477
I. Mátyás Jagelló Ulászló béke / 1478
kenyérmezei csata / 1479
Hainburg elfoglalása Mátyás / 1482
Mátyás II. Bajezid békekötés / 1483
I. Mátyás elfoglalja Bécset / 1485
Habsburg Miksát választják utódnak NRCS / 1486
Bécsújhely elfoglalása Mátyás / 1487
II. Ulászló békekötés Pozsony / 1491`

const kings = `I. István / 1000 - 1038
Orseoló Péter / 1038 - 1041
Aba Sámuel / 1041 - 1044
Orseoló Péter / 1044 - 1046
I. András / 1046 - 1060
I. Béla / 1060 - 1063
Salamon / 1063 - 1074
I. Géza / 1074 - 1077
I. László / 1077 - 1095
Könyves Kálmán / 1095 - 1116
II. István / 1116 - 1131
II. Béla / 1131 - 1141
II. Géza / 1141 - 1162
II. László / 1162 - 1163
III. István / 1162 - 1172
IV. István / 1163 - 1165
III. Béla / 1172 - 1196
Imre / 1196 - 1204
III. László / 1204 - 1025
II. András / 1205 - 1235
IV. Béla / 1235 - 1270
V. István / 1270 - 1272
IV. Kun László / 1272 - 1290
III. András / 1290 - 1301
Vencel / 1301 - 1305
Ottó / 1305 - 1312
Károly Róbert / 1308 - 1342
I. Nagy Lajos / 1342 - 1382
Mária / 1372 - 1385
II. Kis Károly / 1385 - 1386
Mária / 1386 - 1395
Luxemburgi Zsigmond / 1387 - 1437
Habsburb Albert / 1437 - 1439
I. Jagelló Ulászló / 1440 - 1444
V. Utószülött László / 1444 - 1457
I. Mátyás / 1458 - 1490
II. Jagelló Ulászló / 1490 - 1516`

const people = `\
I. Uros szerb nagyzsupán leánya, a későbbi II. (Vak) Béla erőskezű(energikus) felesége (1128/1129-től) / Ilona
NRCS (1138 - 1152), a második keresztes hadjárat során vonult át hadaival Magyarországon / III. Konrád
Magyar katona, III. Henrik (2.) magyarországi hadjáratának meghiúsítója (Vörösmarty Mihály: A buvár Kund) / Zotmund lovag
NRCS (1152 - 1190), a 3.keresztes hadjárat során átvonult Mo.-n, III.Béla bőkezűen megvendélte / I. Frigyes
III. Béla második (Capet-dinasztiából származó) felesége (1186-tól) / Capet Margit
III. Henrik leánya, Salamon (magyar király) felesége (1063-tól) / Judit
Imre herceg nevelője, csanádi püspök, az első pogánylázadás idején vértanú halált halt / Gellért püspök
Az 1. keresztes hadjárat egyik vezére, Könyves Kálmán Magyarországon átengedte, Jeruzsálem elfoglalója / Bouillon Gottfried
Bizánci császár (1071 - 1078), elismerte (koronával) I. Géza királyságát Salamonnal szemben / VII. Dukász Mihály
a Gesta Friderici I. imperatoris (= I. Frigyes császár tettei - benne a II. Géza kori Magyarország leírása is) írója / Freisingi Ottó
a Gesta Hungarorum (= A magyarok cselekedetei 1210 k.) írója / Anonymus
Horvát király (1075 - 1089); özvegyen maradt felesége férje halála után bátyját (I. Lászlót) hívta segítségül / I. Zvoinimir
Könyves Kálmán "állítólagos"fia, később "makacs trónkövetelő" / Borisz
Vazul fia (András és Béla testvére, élete végéig (1047?) pogány maradt / Levente
I. (Szent) István nagybátyjának, Mihálynak a fia, I. István utasítására megvakították / Vazul
Francia király (1137 - 1180), a 2.keresztes hadjárat során átvonult Mo.-n, barátságot kötött II. Gézával / VII. Lajos
Római pápa (1073 - 1085), I. László azzal nyerte el támogatását, hogy a NRCS riválisával lépett szövetségre / VII. Gergely
Pogány magyar előkelő (főtáltos), második pogánylázadás kirobbantója / Vata fia, János
I. (Szent) László és Adelhaid hercegnő leánya (1080-as évek - 1134) II. Iohannész Komnénosz felesége / Prisca
Az első pogánylázadás kirobbantója, Békés vármegyei főúr (esetleg besenyő eredetű) / Vata
I. (Szent) László unokája, nagyhatalmú bizánci császár (1143 - 1180) / I. Manuel
Jezsuita történész (1723 - 1801), ő fedezte fel Raguzában I. (Szent) István épen megmaradt jobbját / Pray György
a Legenda sancti regis Stephani (= Szent István király legendája (1112 - 1116 között) írója / Hartvik püspök
a Chronicon pictum (= Képes krónika= XIV. századi krónikakompozíció 1358 k.) írója / Kálti Márk
Árpád-házi herceg, (1050 - 1095?), I. Béla fia, I. László uralkodása idején a dukátust birtokolta / Lampert
Árpád-házi herceg (1070 k. -1127), I. Géza fia, bátyja ellen többször is fellázadt, aki végül megvakíttatta / Álmos
NRCS (1039 - 1056) többször is megkísérelte hűbéri függésbe kényszeríteni a Magyar Királyságot / III. Henrik
Esztergomi érsek (1158 - 1181), megtagadta a koronázást az ellenkirályoktól (II. László, IV. István) / Bánfi Lukács
II. András harmadik feleségének (Beatrix) és a nádornak (??) a fia (III. András apja) / Postumus István
A Magyarországra 1239-ben bebocsátást nyert kunok fejedelme 1241 márc. 17. után felkoncolták / Kötöny
A nagy interregnum után trónra kerülő német király (1273 - 1291), legyőzte a cseh királyt Morvamezőnél / I. Habsburg Rudolf
IV. Béla győztes ellenfele a muhi csatában, a tatár fősereg fővezére / Batu kán
A Historia Salonitana írója, a tatárjárás egyik (kevésbé ismert) krónikása / Spalatói Tamás
Római pápa (1227 - 1241) között, IV. Béla tőle is segítséget kért (eredménytelenül) a muhi csata után / IX. Gergely
III. András leánya (1338. máj.6-án halt meg), az Árpád-ház utolsó (leányági) leszármazottja / Erzsébet
Erdélyi vajda (a kiskirályok egyike) elfogta, bebörtönözte Wittelsbach Ottót (a Szent Koronát is megszerezte) / Kán László
Ausztria és Stájerország hercege (1230 - 1246), a Babenberg-dinasztia utolsó férfitagja / II. Harcias Frigyes
A Cseh Királyság nagyhatalmú uralkodója (1253 - 1278 között) / II. Ottokár
Váradi főesperes, a Carmen miserabile (= Siralmas ének 1243k.) szerzője / Rogerius
Domonkos rendi szerzetes, kétszer is járt Magna Hungariában / Julianus barát
A Remete Szent Pálról elnevezett magyar Pálos-rend megalapítója (1263-ban kapott a rend pápai engedélyt) / Boldog Özséb
Pápai legátus Magyarországon (1308 - 1311), kieszközölte Károly Róbert megkoronázást (1309. jún. 15.) / Gentilis
V. István magyar király leánya, aki 1270-ben feleségül ment a későbbi nápolyi királyhoz (1285 - 1309) / Mária
Magyar trónkövetelő (1271 - 1295), V. István leányának és a nápolyi királynak (1285 - 1309) a fia / Anjou Martell Károly
IV. Béla szentté avatott (1943) leánya, a Szűz Mária-kolostorban (Insula Leporum) élt haláláig (1270) / Margit
A Bánk bán című dráma (1819) írója / Katona József
A Bánk bán című opera (1861) zeneszerzője / Erkel Ferenc
IV. (Kun) László udvari papja, a Gesta Hungarorum (1282 - 1285 között) szerzője / Kézai Simon
II. András uralkodása idején tárnokmester, majd nádor, IV. Béla megvakíttatta / Ampod fia Dénes
V. István felesége (1254), a kun fejedelem leánya / Kun Erzsébet magyar királyné
IV. Béla és Laskaris Mária leánya (1235 - 1294), V. Boleslaw felesége, 1999-ben a pápa szentté avatta / Kinga
II. András első felesége, meráni hercegnő, a  erdők közelében meggyilkolták / Gertrúd
II. András szentté avatott (1235) leánya, fiatalon megözvegyülve életét teljes szegénységben és aszkézisben töltötte, karitatív tettei miatt már halála előtt szentként tisztelték / Szent Erzsébet
Luxemburgi Zsigmond bátyja, NRCS (1378 - 1400), cseh király (1378 - 1419) / IV. Vencel
az 1437 nyarán kirobbant parasztfelkelés (Bábolna-hegy) vezetője / Budai Nagy Antal
Luxemburgi Zsigmond lengyel híve, pozsonyi ispán, majd erdélyi vajda (1409 - 1414) / Stiborci Stibor
Károly Róbert kisebbik fia (1327 - 1345), a 1345-ben felesége tudtával meggyilkolták / András herceg
I. (Nagy) Lajos kisebbik leánya (1374 - 1399), 1384-től lengyel királynő / Hedvig
Károly Róbert apja (= V. István lányának,(= Mária)és II. (Sánta) Károly nápolyi királynak a fia / Martell Károly
magyar főúr, aki 1330-ban merényletet követett el Károly Róbert és családja ellen / Zách Felicián
a kiskirályok legtekintélyesebb hatalmú tagja (1265 - 1321) "Mátyusföld" ura / Csák Máté
Nápoly királynője (1343 - 1381), I. (Nagy) Lajos királyunk "ellenfele" / I. Johanna
Magyarország egyik leghatalmasabb tartományura, 1311-ben Kassán meggyilkolták / Aba Amadé
Károly Róbert tárnokmestere, gazdaságpolitikájának gyakorlati megvalósítója (1315 - 1338) / Nekcsei Demeter
Károly Róbert sógora, a visegrádi királytalálkozó egyik résztvevője / III. Nagy Kázmér
Havasalföldi vajda (1324-től), Károly Róbertet tőrbe csalta, legyőzte Posadánál / I. Basarab
Nápoly királya (1309 - 1343), vele kötött dinasztikus szerződést Károly Róbert / I. Bölcs Róbert
Esztergomi érsek (1387 - 1418), kétszer is szembefordult Luxemburgi Zsigmonddal / Kanizsai János
Lengyelország királyának leánya (1305 - 1380), Károly Róbert felesége (1320) / Piast Erzsébet
a "lovagkirály" özvegyének híve, a II. (Kis) Károly elleni merénylet elkövetője (1386) / Forgách Balázs
Luxemburgi Zsigmond második felesége (1405), Luxemburgi Erzsébet (1409 - 1442) anyja / Cillei Borbála
Luxemburgi Zsigmond híve, temesi ispán, hadvezér, a déli végvárvonal kiépítője / Filippo Scolari
II. (Kis) Károly utóda (1386 - 1414), Zárában királlyá koronázzák Zsigmonddal szemben / Nápolyi László
Luxemburgi Zsigmondot 1401-ben ő szabadította ki fogságából, nádor (1402 - 1433) / ifj. Garai Miklós
a nápolyi királyt (1386 - 1414) Zsigmonddal szemben támogató pápa (1389 - 1404) / IX. Bonifác
római pápa (1305 - 1314), Károly Róbert támogatója, Magyarországra küldte legátusát, Gentilis / V. Kelemen
litván nagyfejedelem, majd a lengyel királynővel kötött házassága (1386) után lengyel király is / II. Jagelló Ulászló
Károly Róbert hadvezére, majd nádora, nagy győzelmet arat Borsa Kopasz ellen / Debreceni Dózsa
a római pápa (1431 - 1447) nunciusa, akinek utasítására a magyar király megszegte a szegedi békét / Giuliano Cesarini
I. (Nagy) Lajos életrajzának (Res gestae Ludovici regis) írója, küküllői főesperes / Tótsolymosi Apród János
szerb despota (1389 - 1427), Luxemburgi Zsigmonddal megköti a tatai szerződést / Lazarevics István
lengyel fejedelem (1306), majd Lengyelország királya (1320 - 1333), I. Károly apósa / I. Lokietek Ulászló
német-római király (1440 - 1493), NRCS (1452), V. (Postumus) László gyámja / III. Frigyes
V. (Habsburg) Albert osztrák herceg felesége (1421), V. (Postumus) László édesanyja / Luxemburgi Erzsébet
a Képes krónika = XIV. századi krónika-kompozíció (1358) feltételezett szerzője / Kálti Márk
erdélyi püspök, jogtalan tizedkövetelése váltotta ki az erdélyi parasztfelkelést / Lépes György
a Sárkányölő Szent György (1373) gótikus lovasszobor egyik alkotója / Kolozsvári Márton
a Szent Koronát királynéja számára ellopó komorna (tette emlékirataiban olvasható) / Kottaner Jánosné
nádor (1397 - 1402), a Luxemburgi Zsigmond elleni puccs (fogságba vetették a királyt Budán) egyik vezetője / Bebek Detre
stájer főúr, Luxemburgi Zsigmond híve, apósa (1405), horvát-szlavón bán (1406 - 1408) / Cillei Hermann
török szultán (1389 - 1402), ő győzte le Luxemburgi Zsigmondot Nikápolynál / I. Bajezid
török szultán (1421 - 1451), ő győzte le Luxemburgi Zsigmondot Galambócnál / II. Murád
a kiskirályok egyike, erdélyi vajda (1295 - 1315), a Szent Korona "bitorlója" (1307-től) / Kán László
szerb despota (1427 - 1456), Szendrő török kézre kerülésekor Magyarországra menekül / Brankovics György
Hunyadi Mátyás zsoldos seregének (fekete sereg) első zsoldoskapitánya (Szabács ostromakor vesztette életét) / Frantisek Hag
Európa-hírű humanista költő(1434 - 1472), pécsi püspök, személynök, kincstartó, horvát bán / Janus Pannonius
itáliai vándorhumanista, író, a királyi könyvtár őre, anekdotafűzért ír Mátyásrólet / Galeotto Marzio
Habsburg Albert özvegye, V. (Utószülött) László édesanyja, (élt: 1409 - 1442) / Luxemburgi Erzsébet
híres humanistata író, költő, V. László nevelője, később II. Pius néven római pápa (1458 - 1464) / Aeneas Silvius Piccolomineus
Hunyadi János legendás albán szövetségese az 1448. évi őszi hadjárat idején / Kasztrióta György
Hunyadi Mátyás kincstartója, hasonló nevű rokona (Zsigmond) II. Ulászló kincstartója / Ernuszt János
a Hunyadiak híve, váradi püspök, fő- és titkos kancellár, esztergomi érsek (1465 - 1472) / Vitéz János
magyar nádor (1447 - 1458), a szegedi egyezmény aláírója, majd (Habsburg-párti) pártütő / Garai László
I. Mátyás nagybátyja, kormányzója (5 év), de Mátyás lemondatta, a török szultán kivégeztette (1461) / Szilágyi Mihály
temesi ispán, alsó-magyarországi főkapitány, a fekete sereg (egyik) szétverője (1492) / Kinizsi Pál
erdélyi vajda (1441 - 1458), főkapitány (1445), Hunyadi László(egyik) halálra ítéltetője / Újlaki Miklós
ítélőmester, a Chronica Hungarorum (megjelent: Brünn, Augsburg - 1488) írója / Thuróczy János
Hunyadi Mátyás "természetes fia" (1473 - 1504), lemondott trónigényéről, horvát bán / Corvin János
boroszlói polgárlány, ő szülte I. Mátyás természetes (törvénytelen) fiát / Edelpeck Borbála
macsói bán, Nándorfehérvár ostroma idején katonai segítséget nyújt Hunyadi Jánosnak / Kórógyi János
cseh huszita zsoldosvezér, a Felvidék ura, 1462-ben egyezett ki I. Mátyással / Jan Giskra
római pápa (1455 - 1458), az imabulla kiadója / III. Callixtus
horvát vitéz, aki a török zászlótartót a mélybe rántotta magával (1456. júl.21.) / Dugovics Titusz
Szerbia despotája (1427 - 1456), a második rigómezei csata után elfogta Hunyadit / Brankovics György
római pápa (1464 - 1471), 1466-ban kiközösítette Podjebrád György cseh királyt / II. Pál
IV. Jenő pápa legátusa, felszólítására szegte meg I. Ulászló a szegedi békét / Giovanni de Capistrano
I. Mátyás udvarának híres itáliai történetírója, jelentős műve a Rerum Hungaricarum decades IV et dimidia / Antonio Bonfini
Hunyadi János özvegye, a szegedi egyezmény egyik megkötője / Szilágyi Erzsébet
az első magyarországi nyomda alapítója, a Budai Krónika (1473) kinyomtatója / Andreas Hess
magyar nádor (1486 - 1487), I. Mátyás híve, a nádori cikkek "számára születtek" / Szapolyai Imre
esztergomi érsek (1440 - 1465), főkancellár, a Hunyadiak ellenfele, Mátyás leváltotta / Szécsi Dénes
V. László bizalmasa, országos főkapitány (1456. szept.), nov.9-én "lekaszabolták" / Cillei Ulrik
a nándorfehérvári csatában részt vett, 1724-ben szentté avatott ferences szerzetes / Giuliano Cesarini
a Szent Koronát (1440. febr.20 - 21-én) királynője utasítására ellopó komorna (udvarhölgy) / Wolfram Helena
Hunyadi Mátyás második felesége (1476-tól), II. Ulászló "szabálytalan" felesége (1490 - 1500) / Aragoniai Beatrix
Hunyadi Mátyás első felesége (1461), 1464-ben szülésben meghalt / Pojdebrád Katalin`

const questions = `\
Hol folytak jelentős építkezések III. Béla korában (királyi várpalota,kápolna - Porta speciosa)? / Esztergom
Melyik Árpád-házi királyunk pénzein jelent meg először a kettős kereszt? / III. Béla
Hol található ma az I. (Szent) Lászlót (vagy inkább III. Bélát?) ábrázoló Szent László-herma (1406)? / Győr
Honnan (melyik városból) került 1601 után a mai helyére? / Várad
Hol található I. (Szent) László királyunk végső nyughelye (1113 után temették ide)? / Várad
Kik alkották I. (Szent) László királyunk itt felállított lovas szobrát? / Kolozsvári testvérek
Ki írt verset a tatárok ellen 1345-ben legendás győzelmet arató szent királyról Szent László címmel (1853) / Arany János
Hol alapított bencés apátságot (Szent Gilles = Egyed) I. (Szent) László (először itt temették el)? / Somogyvár
Hol temették el I. András királyunkat? / Tihanyi bencés apátság
Melyik monostorban őrizték a XV. század elejéig ereklyeként első királyunk mumifikálódott jobb kezét? / Szent Jobb
Melyik szerzetesrend monostorait alapította III. Béla királyunk Zircen, Szentgotthárdon, Pásztón? / Cisztercita
Hol alapított püspökséget (Horvátországban) I. (Szent) László? / Zágráb
Ki alapította meg a garamszentbenedeki bencés apátságot? / I. Géza
Melyik bencés apátságban temették el I. Béla királyunkat? / Szekszárd
Hol temették el I. Géza királyunkat? / Nagyboldogasszony bazilika
Hol érte súlyos baleset (az összedőlő fatrónus félholtra sebezte) I. Béla királyunkat?
Hogyan szól magyarul Könyves Kálmán törvénye "De strigis, quae nun sunt, nulla quaestio fiat!"
Kb. mekkora lehetett Magyarország demográfiai vesztesége a tatárjárás következtében? / 300k - 400k (15-20%)
Ki kezdte el a budai vár építését (1247) a budai Várhegyre áttelepítve a pesti polgárokat (egy újabb mongol támadás hírére)? / IV. Béla
A XIII. század második felében sok hospestelepülés kapott városi kiváltságokat. Melyik népcsoporthoz tartozott a hospesek zöme? / vendég telepes
Mit jelent a Magna Hunagria történelmi-földrajzi fogalom? / régi Mo. Urál DNY-i oldalán v. őshaza
Mi a neve ma az egykori Magna Hungariának? / Nagymagyarország, Baskíria
Mikor koronázták meg Károly Róbertet a Szent Koronával? / 1310 aug. 27.
Melyik királyunk uralkodása alatt jelentek meg Magyarországon az első céhek? Kb. melyik években? / Nagy Lajos, 1376
Sorold fel az 1360-as években a Magyar Királyság uralma alá tartozó vazallus-államokat (minimum négyet) / Bosznia, Szerbia, Moldávia, Havasföld
Ki volt az a (taborita) huszita zsoldosvezér, aki a Garai-Cillei liga híveként a Felvidék bányavárosait uralta az 1440-es évektől, s utóbb (1445) a hét főkapitány egyike lett? / Giskra János
Sorold fel a kápolnai uniót létrehozó erdélyi három rendi nemzetet! / magyar nemesség, szász patríciusok, székely előkelők
Melyik város volt I. Károly (= Károly)Róbert) uralkodói székhelye 1315 - 1323 között? / Temesvár
Melyik város lett az új uralkodói székhelye 1323-tól? / Visegrád
Mit bányásztak a Károly Róberttől 1329-ben hospesszabadságot kapott Huszt, Sziget, Técső bányászai? / sót
Mikor készült a jelenleg Győrben őrzött Szent László herma? / Zsigmond királysága alatt (1400 - 1425)
Hol volt a Károly Róbert legnagyobb ellenfelének számító Csák Máté tartományának székhelye? / Trencsén
Hol tárták fel a magyarországi gótikus szobrászat jelentős darabjait Zsigmond király ún. Friss-palotájának 1974-es ásatásakor (= hol volt ez a királyi palota)? / Budavár
Melyik országgal háborúzott Luxemburgi Zsigmond 1411 - 1420 között? / Velence
Melyik terület elvesztésével végződött e háború? / Dalmácia
Melyik lengyel királynak zálogosított el 16 szepességi várost (az előbbi háború pénzügyi nehézségei miatt (88 800Ft-ért)? / II. Ulászló
Melyik évben? / 1412
Hány évig tartoztak e városok a lengyel királyok uralma alá? Ki foglalta vissza őket? / 360 évig, ?
Mit jelent a sedes dominialis (latin nyelvű fogalom) magyarul? / úriszék
Milyen országos méltóságokat kapott meg (katonai érdemei révén) Hunyadi János a következő években? 1439, 1441, 1446 / szörényi bán, erdélyi vajda, kormányzó
Hol építtetett gótikus lovagvárat (várpalotát) I. (Nagy) Lajos 1360-1378 között? / Diósgyőr
Mikor fogadták el a budai országgyűlésen az ún. nádori cikkeket (a nádor hatásköreiről szóló törvénycikkeket)? / 1485 dec.
Mikor kötött békét I. Mátyás Bécsújhelyen a rivális német-római császárral? / 1463 júl. 19.
Ki írta a Szép Ilonka című elbeszélő költeményt (1833), amely a Mátyás-kút című szobor alkotóját is megihlette? / Vörösmarty Mihály
Ki írt könyvet Mátyás király családjának római eredetéről Libellus de Corvinae domus origine (= Könyvecske a Corvin-ház eredetéről) címmel (még 1486 előtt)? / Bonfíni Márk Antal
Mikor jött létre az Academia Istropolitana? Hol működött? / 1467, Pozsony
Mikor választották meg Hunyadi Jánost Magyarország kormányzójává? Meddig tartott kormányzósága? / 1446 - 1457
Melyik évben "játszódik" Arany János Mátyás anyja (1854) című híres verse ("Gyermekem! Ne mozdulj Prága városából!")? / 1457
Hol született Hunyadi Mátyás? Mikor? / Kolozsvár, 1443 febr. 23.
Kit akartak a magyar trónra ültetni a Mátyás elleni összeesküvést kirobbantó humanista főpapok? / Vitéz János
Ki volt Magyarországon az összes királyi jövedelmet ellnőrző-irányító kormányzati tisztségviselő (a tárnokmestert váltotta e poszton)? / kincstartó
Ki ígérte meg Farkashidán, hogy "adót, vagy egy forintos taksát semmi esetre sem hajtsunk be, hanem kötelesek legyünk megelégedni a régi királyi jövedelmekkel."? / Werbőczy István
Hol halt meg Hunyadi János (pestisben)? Hol temették el? / Zimony, Gyulafehérvár
Hol halt meg I. Mátyás (= Hunyadi Mátyás)? Hol temették el (18 nappal később)? / Bécs, Székesfehérvár
I. Mátyás kincstári reformjai után a pénzügyeket egységesen irányító gazdasági-kormányzati szerv / ?
I. Mátyás Európa hírű, a pápai könyvtárral vetekedő könyvtárának (latin) elnevezése / Bibliotheca Corviniana
I. Mátyás bírósági reformját követően a királyi személyes jelenlét bíróságának a vezetője / királyi személynök
I. Mátyás legjelentősebb bevétele, portánként 1 forintot tett ki, néha évente többször is kivetették / rendkívüli hadiadó
Kb. mekkora volt I. Mátyás évi (rendes) jövedelme (adókból + regálékból) a fenti (rendkívüli adó) nélkül? / 100 - 120e ft
Melyik három területet foglalta el (haláláig birtokolta ezeket) I. Mátyás a Cseh Királyság "országaiból"? / Szilézia, Morvaország, Lausitz
Mikor koronázták meg I. Mátyást a Szent Koronával? / 1454 márc. 29.
az 1/8 teleknél kisebb birtokú jobbágy elnevezése a XVI. század elejétől / zsellérek
Sorolj fel legalább 8 várost a XV. század elején, amelyek vagy a Garam völgyében fekvő bányavárosokhoz, vagy a szász városokhoz, vagy a személynöki városokhoz tartoztak. / Körmöcbánya, Szemecbánya, Újbánya, Bakabánya, Besztercebánya, Libetbánya, Bélabánya`

const dictionary = `iobagiones castri / várjobbágyok
primogenitura / elsőszülöttség joga
comes castri / várispán
curia / udvarház
ius asylii / menedékjog
ducatus / hercegség
idoneitas / alkalmasság
ius regale / királyi felségjog
servientes regis / királyi szolgák
milites / vitézek
Rama / Bosznia
iudicium Dei / istenítélet
Scepes / szepesség
senioratus / legidősebb férfirokon öröklési joga
comes palatinus / nádor
Corona Graeca / görög korona
hospes / vendég, telepes
notarius / jegyző
banus / bán
lucrum camerae / kamarahaszna
Magyarország ifjabb királya (1262-1270) / iunior rex Hungarie
nemesi felkelés (a nemesség hadba vonulási kötelezettsége) / insurrectio
tárnokmester (a pénzügyi és gazdasági politika irányítója 1214-től) / magister tavernicorum
a kamara haszna / lucrum camerae
királyi felségjog / ius regale
új berendezkedés (új intézkedések) / novae institutiones
a kunok bírája (= a nádor) / iudex Cumaronum
ellenállási jog / ius resistendi
kiskirályok (tartományurak) / oligarchák
szolgabíró / iudex servientium
az ország főméltóságai, világi nagybirtokosok (1216-tól nevezték így őket) / barones
a királyi várszervezet kiemelt jogi helyzetű tagjai / iobagiones castri
világi nagybirtokos szogálatába álló (kis)nemesi személy / familiaries servitor
a királynak (fegyveresen) szolgáló személyek / servientes regis
pápai követ (pl. Jakab a beregi egyezmény kikényszerítője) / legatus
nemesek (a serviensek elnevezése 1267 után) / nobiles
(tényleges) uralkodó nélküli időszak / interregnum
mohamedán kereskedők (vámszedők, pénzváltók) / izmaeliták, böszörmények
egyházi tilalom, fenyítés (pl. Róbert esztergomi érsek 1232) / interdictum
függőpecsét / bulla
hatalmaskodás / factum potentiale
zászlós címer, zászlóalj (kb. 400 fő) / banderium`

const sources = `\
"Félholtan a Kinisva patakhoz vitték, bizonyos országos teendők miatt, és ott költözött el az evilágról. Eltemették pedig Szent Megváltó monostorában, amelyet ő maga építtetett azon a helyen, melyet ..........-nak hívnak. Ez a................ugyanis kopasz volt és barna bőrű, ezért saját monostorát saját külsejéről.........nak nevezte el." / Kálti Márk, Képes Krónika, 1358
"Azoknak, akik a szerpapságot és az áldozópapságot nőtlen állapotban nyerték el, feleséget venni nem szabad.” / Könyves Kálmán, 1115
"Ha valamely szabad ember tíz dénár értékűt lop, akasszák fel, ha tíz dénárnál kisebb értékűt lop, a lopott értéket tizenkétszeresen adja vissza, és egy ökröt fizessen." / I. Szent László, 1092
"Látjátuk feleim szümtükhel, mik vogymuk: isá, por és homu vogymuk." (= "Látjátok, feleim, szemetekkel, mik vagyunk: íme, por és hamu vagyunk.") / III. Béla, Halotti beszéd, 1192 - 1195
"Akik pogány szokás szerint kutak mellett áldoznak, vagy fákhoz, forrásokhoz és kövekhez ajándékot visznek, bűnökért egy ökörrel fizessenek." / I. László, 1092
"Ha valaki négylábú állatot, vagy ennek megfelelő dolgot, avagy húsz dénár értékű ruhát lop, mint tolvajt ítéljék el." / Könyves Kálmán, 1100 k.
"minthogy az emberi természet gyarlósága következtében, s az idő előrehaladtával a letűnt dolgok emlékezetét könnyen elragadja a feledés, illő az írás erejével megerősíteni azt, amiben a szerződő felek egymás között megállapodtak..." / III. Béla, 1181
"és senki sincs a nagy kiterjedésű országban, aki a királyon kívül pénzt merészkedik veretni vagy vámot szedni...ha az ispánok közül valaki a királyt akárcsak kicsiségben is megsérti, vagy ilyesmiért előtte valahányszor csak - égha alaptalanul is - gyanúba keveredik, akkor bármelyik alacsony rangú törvényszolga, akit az udvarból küldtek, egyedül elfogja őt, még akkor is, ha csatlósai körülötte vannak, megbilincseli és különféle kínvallatásokra viszi." / Freisingi Ottó
"Elkezdtük bejárni a puszta és elhagyott földet, amelyet elmenetelünkkor pusztítottak el. Az egyházak tornyai voltak az útmutató jeleink egyik helységből a másikba, és ezek is borzalmas utat jelöltek…a nyolcadik napon Gyulafehérvár városába érkeztünk,ahol semmit sem találtunk, csak megöltek csontjait és koponyáit..." / Rogerius, Siralmas ének, 1243
"Úgyszintén a megyei ispánok vagy alispánok jelenléte elé senkit se lehessen a káptalanok vagy konventek tanúbizonysága nélkül idézni, s a megyei ispán ne merészeljen ítéletet hozni vagy bíráskodni a négy választott nemes nélkül ( = quattuor electi nobiles = [= a nemesség által (maguk közül ) választott 4 szolgabíró ]." / III. András, 1290 - 1291 évi törvénykönyve
"Őt II. András, III. Béla fia követte... Felesége a Németországból = Meránia - Tengermellék jött ....... úrnő volt, akitől IV. Béla, Kálmán, András és Boldog Szent ......... született. Hanem, ó fájdalom, az említett úrnő - kétségkívül az emberi nem ellenségének sugalmazására - Bánk bánnak, ennek a kiváló férfiúnak a feleségét erőszakkal kiszolgáltatta az egyik nála vendégeskedő fivérének, hogy az megbecstelenítse. Emiatt ez a Bor nembeli Bánk irgalmatlanul megfürdette kardját a királyné vérében, és szörnyű sebbel megsebezve az Úr ....... évében meggyilkolta." / Kálti Márk, Képes Krónika, 1358 k.
"Vylag vilaga viragnac viraga. keseruen kynzathul vos scegegkel werethul..." / Ómagyar Mária-siralom, 1300 k.
"...urunk királyunktól alázatosan és áhitattal azt kértük, hogy engedje meg nekünk, hogy mi magunk bíráskodhassunk, és az elnyomottaknak és vég nélkül sérelmeket szenvedőknek teljes igazságot szolgáltassunk, mindazok ellen, akiknek részéről méltánytalanul szenvedünk. A király úr pedig kérésünket kegyesen meghallgatva jóindulatából kifolyólag jóságosan megadta nekünk a kért engedélyt..." / András, Kehidai Oklevél, 1232
"...tudtára adjuk mindazoknak, kik jelen levelünket megtekintik, hogy Magyarország összes nemesei, akiket királyi servienseknek mondanak, hozzánk járulván, tőlünk alázatosan azt kérték, hogy őket…elnyert szabadságukban megtartani kegyeskedjünk... Úgyszintén elrendeltük, hogy minden évben a szent király ünnepén, közülünk valaki tartozzék Fehérvárra jönni, és minden megyéből két vagy három nemes tartozzék megjelenni, hogy azoknak jelenlétében minden kártevésről és jogtalanságról, bárki okozta és követte el azokat, minden panaszosnak elégtételt szolgáltassunk." / IV. Béla, V. István, Aranybulla 2. megújítása, 1267
"...a királyi felség bőkezűségét semmi sem szorítja határok közé, és az uralkodó számára az adományozás legjobb mértéke a mértéktelenség." / II. András, 1208 (1217, új intézkedések)
"Ezenkívül szabad vásár tartását engedélyezzük nekik mind a várban, mind azon kívül.. Azt is elrendeljük, hogy minden Ausztriából Magyarországra jövő és Magyarországról Ausztriába menő kereskedő áruját rakja ki a a győri várban csere végett." / V. István, szabadságlevék, 1271
"Saját akaratunkból hozzájárulván, hogy akár mi, akár a mi fiaink és a mi utódaink ezt az általunk engedélyezett szabadságot meg akarnák sérteni, az esztergomi érseknek jogában álljon, hogy szabályszerű előzetes megintés után, minket és őket a kiközösítés büntetésével fenyítsen meg..." / II. András, Aranybulla 1. megújítása, 1231
"Megtalálta pedig őket a nagy Etil folyó mellett. Akik látván őt, s megértvén, hogy keresztény magyar, nagyon örvendeztek megérkezése felett. Körülvezették őt házaikban és falvaikban, és keresztény magyar véreik királyáról és országáról behatóan tudakozódtak. Bármit mondott nekik a hitről vagy egyebekről, a legfogyelmesebben hallgatták, mivel teljesen magyar a nyelvük; megértették őt, és ő is azokat... A régiek hagyományából tudják, hogy ezek a magyarok tőlük származnak, de hogy hol vannak, nem volt tudomásuk róla." / Julianus barát, 1235 - 1236
"Ha pedig a király az országon kívül akar hadat vezetni, a serviensek ne tartozzanak vele menni, csak az ő pénzén, és visszatérése után rajtuk hadbírságot ne szedjen. Ha azonban az ellenség részéről jön sereg az országba, mindnyájan egyetemlegesen menni tartozzanak..." / II. András, Aranybulla, 1222
"És határozottan kötelezték magukat, hogy eltávozzanak és visszavonuljanak sátraikból és nemezből készült házaikból és keresztény szokás szerint falvakban, földbe épített épületekben és házakban fognak lakni és tartózkodni, s határozottan megígérték, hogy mindenben a keresztény erkölcsökhöz és szokásokhoz fognak alkalmazkodni." / IV. Kun László, Kun törvények, 1279
"Peccaverunt nostri patres / tibi, nos et nostri fratres, / mala nostra succreverunt, / quae nos nimis invenerunt / in diebus angustiae." = "Már apáink bűnben éltek, / mi is vétkeztünk előtted, / s bűneink sokasága / mi fejünkre visszaszálla / nyomorúság idején." / Siralomének a tatárdúlta Magyarországról
"Magyarország főpapajai, bárói,nemesei és minden rendű lakói Ráchelként siratták el Szent István első magyar király nemzetségének,vérének, törzsökének atyai ágon kisarjadt utolsó aranyágacskáját." / Három király harca a szent koronáért, III. András halála után
"Latiatiuc feleym zumtuchel mic vogmuc. ysa pur es chomuv uogmuc. Menyi milostben terumteve eleve miv isemucut Adamut. Es odutta vola neki paradisumut hazóá." / III. Béla, Halotti beszéd, 1192 k.
"Ezek után II. András a pápa úr megbízásából ellátogatott a Szentföldre…Végül a királyi kincstár fölhasználásával megvásárolta különböző szentek ereklyéit,… A szentek értékes kincseivel tért vissza Magyarországra, és országát virágzó állapotban találta. Ezt azonban nem a saját, hanem azon szentek érdemeinek tulajdonította, akiknek az ereklyéit magával hozta." / Kálti Márk, Képes Krónika, 1360 k.
"Kádán vezér minden körülményt gondosan megvizsgálva meggyőződött, hogy a víz, amely a várat a szárazföldtől elválasztotta, mélysége miatt járhatatlan." / Spalatói Tamás, Historia Salonitana
"Ezenfelül, ha azoknak a városoknak a polgárai, melyeket újonnan létesítettünk és amelyeket más városok szabadságaival láttunk el, bíráik és esküdtjeik ítéletében és határozatában megnyugodni nem akarnak, megannyian ahhoz a városhoz, amelynek szabadságaival élnek vagy tárnokmesterünkhöz fellebbezhetnek." / 1405
"Ugyanazon nemeseknek a kérésére ahhoz is hozzájárultunk, hogy mind az országunk határai között lakó valódi nemesek, mind pedig azok is, akik országaink határain belül fekvő hercegi tartományokban élnek, egy és ugyanazon szabadságot élvezzék." / 1351
"az ország nádorát...a királyi felség a főpapoknak,báróknak és az ország nemeseinek tanácsára velük egyetértőleg válassza ki." / 1439
"Végre egybegyülekezésünk után, főpapjaink, báróink, valamint az ország testét a távollevők teljes hatalmával képviselő országos nemesek egyértelmű tanácsára, közös megbeszélésével és beleegyezésükkel elhatároztuk." / 1435
"ezenkívül bármely néven nevezett minden szabad faluban, sőt még az udvarnoki és királynéi falvakban élő valamennyi szántóvető és szőlőbirtokos jobbágyainktól is - kivéve a fallal övezett városokat - ezek minden terményének és borának a kilencedrészét fogjuk beszedetni, és a királyné asszony is be fogja hajtani és a fent említett bárók és a nemesek bármely birtokaikon élő minden szántóvető és szőlőbirtokos jobbágyuktól hasonlóképpen hajtsák be, és szedjék be azok minden terményének és borának a kilencedrészét a saját szükségletükre" / 1351
"az ország mindenik hadjáratára uradalmaik mennyiségéhez képest állítsanak katonát, tudniillik minden 33 jobbágy után....egy tegzes lovast, száz után pedig hármat, ..." / 1435
"...minden mérhető és mázsálható dolognak kimérése és mázsálása minden városban, a mezővárosokban, várakban, s egyáltalán országunk határain belül mindenütt Buda városunk mértéke szerint történjék." / 1405
"...éppen ellenkezőleg: egyáltalán ne legyen joguk ezt megtenni, hanem birtokaik jog szerint és törvényesen tisztán és egyszerűen, minden ellentmondás nélkül legközelebbi atyafiaikra, nemzetségeikre áramoljanak." / 1351
"Meg is állapodtak tízévi fegyvernyugvásban; a király pedig szabadon bocsátotta az anatóliai basát. A török császár[=............] Szendrő várát és Rácország többi erődítményét, ahányat csak elvett [=.................] despotától, visszaadta. A fegyvernyugvást esküvel erősítették meg. A vajda úr [=...............] a király [=..................] és a maga nevében meg az egész magyar nép nevében, az említett török méltóságok pedig császárjuk és az uralma alá tartozó minden nép nevében le is tették az esküt a mondott tízévi fegyverszünet és az egyéb kikötések megtartására. Bár sohase tették volna le! Mert utóbb a fegyverszünetet a király és a vajda úr - az említett bíboros úr [=..........................................] ösztönzésére - megszegte" / Amurát, György, János, Mátyás, Julianus, Thuróczy János: Magyarok krónikája, 1488 
"Nemkülönben fizetés portánként legyen, mégpedig úgy, hogy ha egy egész telken vagy ülésen - hozzáértve azt a földet, mely ehhez tartozik - egy portán belül csak egy (család) lakik, az fizessen 20 dénárt. Ha pedig ketten kapnának egy telket és egy telek földjét egy portán belül mindkettőjük fizessen egy és egy fél porta szerint." / 1467, Kincstári reform
"Itt nyugszik János, harcokban réme töröknek. / Dicsfényben ragyogott őrhelye Pannonián. / .....................................( a sírfelirat szerzője ) Szülte Hunyad, majd mint ispánt, Beszterce uralta. / Nándori Albán halt - nyughelye Alba-Iulae." / Janus Pannonius, 1456, Hunyadi János sírverse
"A király úr kedvező szerencsével intézte dolgait Csehországban, de közben Magyarország némely urai, leginkább éppen azok, akiket az ő jóindulata emelt magas méltóságokra, főként pedig a két...........[név!] úr, az egyik az esztergomi érsek [=....................], a másik meg a pécsi püspök [ =...........................],akik alacsony, köznemesi fedél alatt születtek Szlavóniában, hálátlan lélekkel megfeledkezve a király jótéteményeiről, nemcsak megzavarták a királyt csehországi ügyeinek intézésében, hanem kísérletet tettek arra, hogy Magyarország jogarától és királyságától megfosszák." / János, Vitéz János, 1488, Csezmicei János, Thuróczi János, A magyarok krónikája
"2. Másodszor: ha valamely király kiskorú örököst hagyna maga után, a nádor tiszténél fogva mint az ő gyámja tartozik és köteles őt apjától rámaradt országaiban és uradalmaiban megtartani és hűségesen megvédelmezni." / 1471,  Cornus jURIS hungarici - I. Mátyás 3. dekrétuma
"Továbbá (...) elhatároztatott és végeztetett, hogy , ha a Magyar Királyság [ trónja] megüresedik, mert az előbb mondott.....király ágyékából nem származnak törvényes fiak vagy unokák, mi (...) vagy fiunk, vagy, ha többet hagyunk hátra, közülük az egyik, akit a királyság királyának választott, Magyarország ama királyságának élére állíttatnak vagy állítttatik (...)" / 1463, egyezség a korona visszaadásáról, Mátyás, III. Frigyes`

const arts = `\
Cillei és a Hunyadiak (dráma) / Vörösmarty Mihály
Mátyás király lovas szobra (Kolozsvárott) / Fodrusz János
Dugovics Titusz hőstette (festmény) / Wagner Sándor
Hunyadi László búcsúja (festmény) / Benczúr Gyula
Hunyadi János lovas szobra (1956 - Pécsett) / Pátzay Pál
Mátyás-kút (1902 - budai vár) / Stróbl Alajos
V. László és Cillei (festmény) / Madarász Viktor
Hunyadi László siratása (festmény) / Székely Bertalan
V. László (ballada) / Arany János
Mátyás király életéről való elmélkedések (1656-1657) / Zrínyi Miklós
Hunyadi László (opera) / Erkel Ferenc`


const categories = [
  {
    name: "Events",
    icon: faCalendar,
    items: events.split("\n").map(x => x.split(" / ")),
  },
  {
    name: "Kings",
    icon: faCrown,
    items: kings.split("\n").map(x => x.split(" / ")),
  },
  {
    name: "People",
    icon: faUser,
    items: people.split("\n").map(x => x.split(" / ")),
  },
  {
    name: "Questions",
    icon: faQuestion,
    items: questions.split("\n").map(x => x.split(" / ")),
  },
  {
    name: "Dictionary",
    icon: faLanguage,
    items: dictionary.split("\n").map(x => x.split(" / ")),
  },
  {
    name: "Sources",
    icon: faBook,
    items: sources.split("\n").map(x => x.split(" / ")),
  },
  {
    name: "Arts",
    icon: faPaintbrush,
    items: arts.split("\n").map(x => x.split(" / ")),
  }
]

const specialMap = {
  "á": "a",
  "é": "e",
  "ó": "o",
  "ö": "o",
  "ő": "o",
  "ú": "u",
  "ü": "u",
  "ű": "u",
  "í": "i",
};

function App() {
  const [categoryIdx, setCategoryIdx] = useState(0);
  const [isSearchShown, setSearchShown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const category = categories[categoryIdx];
  let filteredCategory = {...category};
  
  if (searchQuery.trim().length !== 0) {
    let normalizedQuery = searchQuery.toLowerCase().replace(/[áéóöőúüűí]/g, x => specialMap[x]);
    let tokens = normalizedQuery.split(" ").filter(x => x.trim().length !== 0);
    filteredCategory.items = [...category.items].filter(item => {
      let normalizedItem = item.join(" ").toLowerCase().replace(/[áéóöőúüűí]/g, x => specialMap[x]);
      // let itemTokens = normalizedItem.split(" ").filter(x => x.trim().length != 0);
      return tokens.every(x => normalizedItem.includes(x))
    })
  }

  return (
    <div className="App">
      <div className="List">
        <div className="Padder"></div>
        {filteredCategory.items.map(([question, answer], i) => (
          <div key={i} className="ListItem">
            <span className="Question">{question}</span>
            <span className="Answer">{answer}</span>
          </div>
       ))}
      </div>
      {isSearchShown ? (<div className="SearchBar">
        <input
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        >
        </input>
        <button onClick={() => setSearchQuery("")}>
          X
        </button>
      </div>) : null}
      <div className="Selector">
        {categories.map((x, i) => (
          <button onClick={() => setCategoryIdx(i)} key={i}>
            <img alt={x.name} src={x.icon}></img>
          </button>
       ))}
        <button onClick={() => setSearchShown(!isSearchShown)} key="search">
          <img src={faSearch} alt="Search"></img>
        </button>
      </div>
    </div>
 );
}

export default App;
